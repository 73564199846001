export function Logo(props) {
  return (
    <svg width="546" height="546" viewBox="0 0 546 546" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clip-path="url(#clip0_3620_357)">
        <path d="M264.987 200.961C264.987 160.363 297.811 127.564 338.44 127.564C379.068 127.564 411.892 160.409 411.892 200.961" stroke="white" stroke-width="44.0648" stroke-miterlimit="10" stroke-linecap="round" />
        <path d="M412.03 408.171L412.03 200.961" stroke="white" stroke-width="44.0648" stroke-miterlimit="10" stroke-linecap="round" />
        <path d="M264.935 409.074L264.987 200.961" stroke="white" stroke-width="44.0648" stroke-miterlimit="10" stroke-linecap="round" />
        <path d="M118.137 200.96C118.37 160.363 151.382 127.752 192.01 127.985C232.638 128.218 265.273 161.206 265.04 201.803" stroke="white" stroke-width="44.0648" stroke-miterlimit="10" stroke-linecap="round" />
        <path d="M118.169 195.409L116.949 408.12" stroke="white" stroke-width="44.0648" stroke-miterlimit="10" stroke-linecap="round" />
        <path d="M339 198L338 377" stroke="white" stroke-width="42" stroke-miterlimit="10" stroke-linecap="round" />
        <path d="M192 197L191 377" stroke="white" stroke-width="42" stroke-miterlimit="10" stroke-linecap="round" />
      </g>
      <defs>
        <clipPath id="clip0_3620_357">
          <rect width="546" height="546" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
